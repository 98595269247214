.header-wrap {
  filter: drop-shadow(0 0 1rem #000);
}

header {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../public/header.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: none;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
}

#logo {
  width: 40%;
  min-width: 100px;
  max-width: 500px;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
}

.row {
  color: white;
  text-align: center;
  padding: 1.5em clamp(0.5rem, 10vw, 25rem);
}

.row h1{
  margin: 0px;
  font-size: clamp(1.5rem, 2.5vw, 2rem);
}

.row p{
  font-size: clamp(0.8rem, 0.8vw, 1rem);
}

.row ul {
  margin-left: -1em;
}

.row li {
  padding: clamp(0.2em, 1vw, 0.4em) 0;
  font-size: clamp(0.8rem, 0.8vw, 1rem);
}

.secondary-wrapper{
  filter: drop-shadow(0 0 1rem #000);
}

.secondary{
  color: #333;
  padding: 2.5em clamp(1rem, 10vw, 25rem);
  background-color: rgb(224, 224, 224);
  clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0 100%);
}

.left {
  text-align: left;
}
.right {
  text-align: right;
}

.imageList {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: wrap;
}

.imageList li {
  margin: 0px auto;
}

.imageList img {
  width: 200px;
}

.fullTriangle{
  max-width: 250px;
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.footer img{
  order: 1;
  flex: 0.5%;
  margin: 0 1em;
}
.footer p{
  order: 2;
  flex: 0.5;
  margin: 0 1em;
}